import React, { useCallback } from 'react'
import { TmapLogBuilder } from '@tmap-web-lib-close/logbox-client'

interface Props {
  allPoliciesChecked: boolean;
  setAllPoliciesChecked: React.Dispatch<React.SetStateAction<boolean>>;
  setIsCheckedList: React.Dispatch<React.SetStateAction<boolean[]>>;
  eventLog: () => TmapLogBuilder;
}

function useHandleAllTermsChecked(props: Props) {
  const {
    allPoliciesChecked,
    setAllPoliciesChecked,
    setIsCheckedList,
    eventLog
  } = props
  return useCallback(() => {
    eventLog().set('action_id', 'tap.agreeAll').send()
    if (allPoliciesChecked) {
      setAllPoliciesChecked(false)
      setIsCheckedList((prev) => new Array(prev.length).fill(false))
    } else {
      setAllPoliciesChecked(true)
      setIsCheckedList((prev) => new Array(prev.length).fill(true))
    }
  }, [allPoliciesChecked, eventLog, setAllPoliciesChecked, setIsCheckedList])
}

export { useHandleAllTermsChecked }
