import React from 'react'
import { SubTermsInfoType } from '../../components/Dialog/DialogGeneralTerms'
import { classNames } from '@tmap-web-lib/utils'

interface Props {
  terms: SubTermsInfoType;
  isCheckedList: boolean[]
  handleSubTermsClick: (terms: SubTermsInfoType) => void
}

function SubTermsContent(props: Props) {
  const { terms, isCheckedList, handleSubTermsClick } = props

  return (
    <button
      className={classNames('terms_checkbox_item_label', 'sub', isCheckedList[terms.sortNo - 1] ? 'checked' : '')}
      onClick={() => {
        handleSubTermsClick(terms)
      }}
    >{terms.title}
    </button>
  )
}

export { SubTermsContent }
