import React, { forwardRef, HTMLAttributes, memo, PropsWithChildren } from 'react'
import { classNames } from '@tmap-web-lib/utils'

interface Props extends HTMLAttributes<HTMLDivElement> {}

const DialogBackground = memo(forwardRef<HTMLDivElement, Props>(
  function DialogBackground({ children, className, ...attrs }: PropsWithChildren<Props>, ref) {
    return <div ref={ref} className={classNames("layer_wrap", className)} {...attrs}>{children}</div>
  }
))

export { DialogBackground }
