import React, { useMemo } from 'react'
import debounce from 'lodash-es/debounce'
import { TmapApp } from '@tmap-web-lib/tmap-app-interface'
import { store } from '../../../store'
import {
  selectSettingsEuk,
  setIsCarLifeTermsAgreed,
  setOptionalMarketingTermsStatus,
} from '../../../store/settings'
import { DialogBaseProps } from '../../../components'
import { KEYS, useUpdateTermsAgreement } from '../../../react-query'
import { TmapLogBuilder } from '@tmap-web-lib-close/logbox-client'
import { useQueryClient } from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import { trackErrorLogs } from '../../../utils'
import { DateTime } from 'luxon'
import { TermsAgreements, TermsGroupDetailResType } from '@tmap-web-lib/remote-api-client/frontman'

interface Props extends DialogBaseProps {
  termsData: TermsAgreements[];
  termsGroupData: TermsGroupDetailResType | undefined;
  isDisabled: boolean;
  isCheckedList: boolean[];
  eventLog: () => TmapLogBuilder;
  showErrorDialog: () => void;
  onSuccess?: () => void;
  isDialogOpen?: boolean;
  setModalIsOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}

function useAgreeTerms(props: Props) {
  const {
    termsData,
    termsGroupData,
    isDisabled,
    isCheckedList,
    eventLog,
    setModalIsOpen,
    showErrorDialog,
    onSuccess,
    onOk,
    isDialogOpen,
  } = props
  const euk = useSelector(selectSettingsEuk)
  const updateTermsAgreement = useUpdateTermsAgreement()
  const queryClient = useQueryClient()

  return useMemo(() => debounce(async () => {
    if (isDisabled) {
      TmapApp.makeToast({ msg: '필수 약관에 동의해주세요.' })
    } else {
      // 운전점수가 아닌 다른 곳에서 동의했을 경우 동의여부값이 갱신되지 않도록 동의여부 확인
      const list: Pick<TermsAgreements, 'allowCode' | 'allowYn' | 'allowTitle'>[] = []
      const parentTermsList = termsGroupData?.termsAgreeList.filter((item) => !item.parentTermsCode)
      const termsCode = parentTermsList && parentTermsList[parentTermsList.length - 1].termsCode
      const updateDate = DateTime.now().toFormat('yyyyMMdd')
      const { targetCount } = JSON.parse(termsGroupData?.additionalData || '{}')

      if (termsGroupData) {
        termsGroupData.termsAgreeList.forEach((terms, index) => {
          const isSubList = terms.parentTermsCode
          const parentTerms = termsData.find(item => item.allowCode === isSubList)
          const foundTerms = termsData.find(item => item.allowCode === terms.termsCode)

          if (foundTerms?.allowYn === 'N') {
            list.push({
              'allowCode': terms.termsType ?`${terms.termsType}${terms.termsCode}` : `${terms.termsCode}`,
              'allowTitle': `${terms.termsViewTitle}`,
              'allowYn': isCheckedList[index] ? 'Y' : 'N',
            })
          }

          if (isSubList && parentTerms?.allowYn === 'N') {
            list.push({
              'allowCode': `${terms.termsType}${terms.termsCode}`,
              'allowTitle': `${terms.termsViewTitle}`,
              'allowYn': isCheckedList[index] ? 'Y' : 'N',
            })
          }
        })
      }

      if (!isCheckedList[3]) {
        store.dispatch(setOptionalMarketingTermsStatus(JSON.stringify([{ termsCode: termsCode, updateDate: updateDate, count: 0 }])))
      } else {
        store.dispatch(setOptionalMarketingTermsStatus(JSON.stringify([{ termsCode: termsCode, updateDate: updateDate, count: targetCount || 2 }])))
      }

      if (list.length) {
        await updateTermsAgreement.mutateAsync(list.map((terms) => terms), {
          onSuccess: () => {
            eventLog().set('action_id', 'tap.agreeContinue').send()
            TmapApp.recordEvent({ name: 'drivingscore_Agreement_continue', json: { euk: euk } })
            store.dispatch(setIsCarLifeTermsAgreed(true))
            onSuccess?.()
          },
          onError: (e) => {
            trackErrorLogs(e, 'tscore:error:updateTermsAgreement')
            showErrorDialog()
          },
          onSettled: () => {
            queryClient.invalidateQueries([KEYS.TERMS_AGREEMENT('UTERMS-B2C-1')])
            queryClient.invalidateQueries([KEYS.TERMS_AGREEMENT('UTERMS-B2C-5')])
            queryClient.invalidateQueries([KEYS.TERMS_AGREEMENT('TTERMS-B2C-18')])
            termsGroupData?.termsAgreeList.forEach((terms) => {
              if (terms.parentTermsCode) return
              queryClient.invalidateQueries([KEYS.TERMS_AGREEMENT(terms.termsCode)])
            })
            setModalIsOpen?.(!isDialogOpen)
            onOk?.()
          },
        })
      }
    }
  }, 500, {
    leading: true,
    trailing: false,
  }), [isDisabled, isCheckedList, eventLog, euk, onOk, queryClient, updateTermsAgreement, setModalIsOpen, showErrorDialog, onSuccess, isDialogOpen, termsGroupData, termsData])
}

export { useAgreeTerms }
