import React, { useCallback, useMemo } from 'react'
import { TmapLogBuilder } from '@tmap-web-lib-close/logbox-client'
import { useOpenServiceUrl } from '../../hooks'
import { classNames } from '@tmap-web-lib/utils'
import { SubTermsInfoType, TermsInfoType } from '../../components/Dialog/DialogGeneralTerms'
import { useHandleParentTermsChecked, useHandleSubTermsChecked } from './hook'
import { SubTermsContent } from './SubTermsContent'

interface Props {
  termsInfo: TermsInfoType[];
  isCheckedList: boolean[];
  setIsCheckedList: React.Dispatch<React.SetStateAction<boolean[]>>;
  setAllPoliciesChecked: React.Dispatch<React.SetStateAction<boolean>>;
  eventLog: () => TmapLogBuilder;
  isSingleTerms?: boolean
}

function TermsContents(props: Props) {
  const {
    termsInfo,
    isCheckedList,
    setIsCheckedList,
    setAllPoliciesChecked,
    eventLog,
    isSingleTerms
  } = props

  const openServiceUrl = useOpenServiceUrl()

  const handleIsChecked = useCallback((index: number, terms: TermsInfoType) => {
    eventLog().set('action_id', terms.logActionId).send()
    const updatedCheckedState = isCheckedList.map((checkedState, i) => i === index ? !checkedState : checkedState)
    const isAllChecked = updatedCheckedState.every((checkedState) => checkedState)
    setAllPoliciesChecked(isAllChecked)
    setIsCheckedList(updatedCheckedState)
  }, [isCheckedList, setIsCheckedList, setAllPoliciesChecked, eventLog])

  const handleParentTermsChecked = useHandleParentTermsChecked({
    isCheckedList,
    setAllPoliciesChecked,
    setIsCheckedList,
  })

  const handleParentTermsClick = useCallback((terms: TermsInfoType) => {
    eventLog().set('action_id', terms.logActionId).send()
    if (terms.subTermsList) handleParentTermsChecked(terms.sortNo - 1, terms.subTermsList)
  }, [handleParentTermsChecked, eventLog])

  const handleSubTermsChecked = useHandleSubTermsChecked({
    isCheckedList,
    setAllPoliciesChecked,
    setIsCheckedList
  })

  const handleSubTermsClick = useCallback((terms: SubTermsInfoType) => {
    eventLog().set('action_id', terms.logActionId).send()
    const parentTerms = termsInfo.find(item => item.termsCode === terms.parentTermsCode)
    if (parentTerms && parentTerms.subTermsList) {
      handleSubTermsChecked(terms, parentTerms.sortNo - 1, parentTerms.subTermsList)
    }
  }, [eventLog, handleSubTermsChecked, termsInfo])

  const subTermsContent = useCallback((subTerms: SubTermsInfoType[]) => {
    return subTerms.map((terms) => {
      return (
        <div key={terms.seq} className={classNames('terms_checkbox_item', terms.required ? 'sub' : 'sub_opt')}>
          <SubTermsContent
            terms={terms}
            isCheckedList={isCheckedList}
            handleSubTermsClick={handleSubTermsClick}
          />
        </div>
      )
    })
  }, [handleSubTermsClick, isCheckedList])

  const displayTermsContent = useMemo(() => {
    return termsInfo.map((terms, index) => {
      if (!terms.isAgreed) {
        if (terms.subTermsList && terms.subTermsList.length) {
          return (
            <div key={terms.seq} className={isSingleTerms ? 'terms_checkbox_single_terms' : 'terms_checkbox_wrap'}>
              {/* 상위 약관 */}
              <div className="terms_checkbox_item">
                <button
                  className={classNames('terms_checkbox_item_label', isCheckedList[terms.sortNo - 1] ? 'checked' : '')}
                  onClick={() => handleParentTermsClick(terms)}
                >{terms.title}
                </button>
                <button
                  className="terms_checkbox_item_link"
                  onClick={() => openServiceUrl(terms.link, 'terms')}
                >
                  약관 내용보기
                </button>
              </div>
              {/* 하위 약관 */}
              <div className="terms_checkbox_sub_terms">
                {subTermsContent(terms.subTermsList)}
              </div>
            </div>
          )
        } else {
          return (
            <div key={terms.seq} className="terms_checkbox_wrap">
              <div className="terms_checkbox_item">
                <button className={classNames('terms_checkbox_item_label', isCheckedList[index] ? 'checked' : '')}
                        onClick={() => handleIsChecked(index, terms)}
                >{terms.title}
                </button>
                <button
                  className="terms_checkbox_item_link"
                  onClick={() => openServiceUrl(terms.link, 'terms')}
                >
                  약관 내용보기
                </button>
              </div>
            </div>
          )
        }
      } else {
        return null
      }
    })
  }, [termsInfo, openServiceUrl, handleIsChecked, isCheckedList, subTermsContent, handleParentTermsClick, isSingleTerms])

  return (
    <>
      {displayTermsContent}
    </>
  )
}

export { TermsContents }
