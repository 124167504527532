import { SubTermsInfoType } from '../../../components/Dialog/DialogGeneralTerms'
import { useCallback } from 'react'

interface Props {
  isCheckedList: boolean[];
  setAllPoliciesChecked: React.Dispatch<React.SetStateAction<boolean>>;
  setIsCheckedList: React.Dispatch<React.SetStateAction<boolean[]>>;
}

function useHandleParentTermsChecked(props:Props) {
  const { isCheckedList, setAllPoliciesChecked, setIsCheckedList } = props

  return useCallback((parentTermsIndex: number, subTermsList: SubTermsInfoType[]) => {
    const updatedCheckedState: boolean[] = []

    isCheckedList.forEach((checkedState, i) => {
      if (i === parentTermsIndex) {
        updatedCheckedState.push(!checkedState)
      } else {
        updatedCheckedState.push(checkedState)
      }
    })

    subTermsList.forEach((sub) => {
      if (parentTermsIndex !== null) {
        // 상위 약관 상태를 따라감
        updatedCheckedState[sub.sortNo - 1] = updatedCheckedState[parentTermsIndex]
      }
    })
    const isAllChecked = updatedCheckedState.every((checkedState) => checkedState)
    setAllPoliciesChecked(isAllChecked)
    setIsCheckedList(updatedCheckedState)
  },[isCheckedList, setAllPoliciesChecked, setIsCheckedList])
}

export { useHandleParentTermsChecked }
