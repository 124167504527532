import { useCallback, useEffect, useMemo, useState } from 'react'
import { TERMS_URL, TermsInfoType } from '../../../components'
import { TermsGroupDetailResType, TermsAgreements } from '@tmap-web-lib/remote-api-client/frontman'

interface Props {
  termsGroupData: TermsGroupDetailResType | undefined
  termsData: TermsAgreements[]
}

function useGetTermsInfo(props: Props) {
  const { termsGroupData, termsData} = props

  const [termsInfo, setTermsInfo] = useState<TermsInfoType[] | null>(null)

  const getIsAgreed = useCallback((termsCode: string, termsAgreementData: TermsAgreements[]) => {
    const foundTerm = termsAgreementData.find(terms => termsCode === terms.allowCode)
    return foundTerm ? !(foundTerm.allowYn !== 'Y') : false
  }, [])

  const getTermsDetailUrl = useCallback((termsCode: string) => {
    return `${TERMS_URL}${termsCode}`
  }, [])

  const getLogActionId = useCallback((termsCode: string) => {
    if (termsCode === 'UTERMS-B2C-1') {
      return 'tap.agreedrivingdata'
    } else if (termsCode === 'UTERMS-B2C-5') {
      return 'tap.agreeOpt_bizdata'
    } else if (termsCode === 'UTERMS-B2C-18') {
      return 'tap.agree_expressway3rdParty'
    } else {
      const split = termsCode.split('_')
      const code = split[split.length - 1]

      if (code === '1') {
        return 'tap.agree_tele-marketing_tel'
      } else if (code === '2') {
        return 'tap.agree_tele-marketing_sms'
      } else if (code === '3') {
        return 'tap.agree_tele-marketing_push'
      } else {
        return 'tap.agree_tele-marketing'
      }
    }
  }, [])

  useEffect(() => {
    if (termsGroupData && termsData.length) {
      const list: TermsInfoType[] = []

      termsGroupData.termsAgreeList.forEach((terms) => {
        if (terms.parentTermsCode !== null) {
          const parentTerms = termsGroupData.termsAgreeList.find(item => item.termsCode === terms.parentTermsCode)
          if (parentTerms) {
            const parentTermsList = list.find((item) => item.termsCode === parentTerms.termsCode)
            const parentTermsListIndex = list.findIndex((item) => item.termsCode === parentTerms.termsCode)
            if (!parentTermsList?.subTermsList) {
              list[parentTermsListIndex].subTermsList = []
            }
            list[parentTermsListIndex].subTermsList?.push({
              sortNo:terms.sortNo,
              seq: terms.termsAgreeSeq,
              parentTermsCode: terms.parentTermsCode,
              termsCode: terms.termsCode,
              isAgreed: getIsAgreed(terms.termsCode, termsData),
              title: terms.termsViewTitle ?? terms.termsTitle,
              link: '',
              logActionId: getLogActionId(terms.termsCode),
              required: terms.mandatoryYn === 'Y'
            })

          }
        } else {
          list.push({
            sortNo: terms.sortNo,
            seq: terms.termsAgreeSeq,
            parentTermsCode: terms.parentTermsCode,
            termsCode: terms.termsCode,
            isAgreed: getIsAgreed(terms.termsCode, termsData),
            title: terms.termsViewTitle ?? terms.termsTitle,
            link: getTermsDetailUrl(terms.termsCode),
            logActionId: getLogActionId(terms.termsCode),
          })
        }
      })

      if (list.length) {
        setTermsInfo(list)
      }
    }
  }, [termsGroupData, termsData, getTermsDetailUrl, getLogActionId, getIsAgreed])

  return useMemo(() => {
    if (termsInfo) {
      return termsInfo
    }
  }, [termsInfo])
}

export { useGetTermsInfo }
