import React, { useCallback } from 'react'
import { useOpenServiceUrl } from '../../hooks'
import { TmapLogBuilder } from '@tmap-web-lib-close/logbox-client'

interface Props {
  eventLog: () => TmapLogBuilder
}

function MainSettings(props: Props) {
  const { eventLog } = props
  const openServiceUrl = useOpenServiceUrl()

  const handleClickSettings = useCallback(() => {
    eventLog().set('action_id', 'tap.setting').send()
    openServiceUrl('tmap://life?pageid=settings')
  },[eventLog, openServiceUrl])

  return (
    <button className="main_settings_btn" onClick={handleClickSettings}>
      카라이프설정
    </button>
  )
}

export { MainSettings }
