import { MouseEvent, useCallback, useEffect, useState } from 'react'

/**
 * READY: 오픈 전
 * OPEN: 오픈
 * CLOSE: 닫기
 * END: 닫기 애니메이션 종료
 */
export enum OtpTooltipStatus {
  READY = 0,
  OPEN = 1,
  CLOSE = 2,
  END = 3,
}

function useOtpTooltip() {
  const [openOtpTooltip, setOpenOtpTooltip] = useState<OtpTooltipStatus>(OtpTooltipStatus.READY)

  const handleOtpTooltipClick = useCallback((e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
    setOpenOtpTooltip(OtpTooltipStatus.CLOSE)
  }, [])

  const handleOtpTooltipTransitionEnd = useCallback(() => {
    openOtpTooltip === OtpTooltipStatus.CLOSE && setOpenOtpTooltip(OtpTooltipStatus.END)
  }, [openOtpTooltip])

  useEffect(() => {
    setOpenOtpTooltip(OtpTooltipStatus.OPEN)
  }, [])

  useEffect(() => {
    if (openOtpTooltip === OtpTooltipStatus.OPEN) {
      const timerId = setTimeout(() => setOpenOtpTooltip(OtpTooltipStatus.CLOSE), 1500)
      return () => clearTimeout(timerId)
    }
  }, [openOtpTooltip])

  return {
    openOtpTooltip,
    handleOtpTooltipClick,
    handleOtpTooltipTransitionEnd,
  }
}

export { useOtpTooltip }
