import { ButtonTapHighlight } from '../../components'
import React, { useCallback } from 'react'
import { TmapLogBuilder } from '@tmap-web-lib-close/logbox-client'
import { useSelector } from 'react-redux'
import { selectSettingsEuk } from '../../store/settings'
import { TmapApp } from '@tmap-web-lib/tmap-app-interface'

interface Props {
  handleTermsCheck: () => void
  eventLog: () => TmapLogBuilder
}

function MainTermsNotAllowed(props: Props) {
  const { handleTermsCheck, eventLog } = props

  const euk = useSelector(selectSettingsEuk)

  const handleClick = useCallback(() => {
    eventLog().set('action_id', 'tap.start_DrivingScore').send()
    TmapApp.recordEvent({ name: 'tap.start_DrivingScore', json: { euk: euk } })
    handleTermsCheck()
  }, [euk, handleTermsCheck, eventLog])

  return (
    <div className="main_header_not_allowed">
        <img
          src={require('../../assets/images/img_carcare_item_score.svg').default}
          alt=""
        />
        <img
          src={require('../../assets/images/img_carcare_item_tire.svg').default}
          alt=""
        />
        <img
          src={require('../../assets/images/img_carcare_item_warranty.svg').default}
          alt=""
        />
        <img
          src={require('../../assets/images/img_carcare_item_oil.svg').default}
          alt=""
        />
        <img
          src={require('../../assets/images/img_carcare_item_insure.svg').default}
          alt=""
        />

      <p>운전점수 쌓고, 정말 쉬운 내 차 관리까지</p>
      <ButtonTapHighlight
        className="main_header_not_allowed_btn"
        onClick={handleClick}
        isFullWidth={false}
      >
        운전점수 시작하기
      </ButtonTapHighlight>
    </div>
  )
}

export { MainTermsNotAllowed }
