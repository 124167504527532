import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
  DialogBackground,
  DialogCancelButton, DialogContent,
  DialogFooter,
  DialogOkButton
} from './Elements'
import { DialogWithAnimationProps } from './types'
import { classNames, getSearchParams } from '@tmap-web-lib/utils'
import { CSSTransition } from 'react-transition-group'
import { useLogBox } from '../../hooks'
import { TERMS_TELE_MARKETING_LOG_PRESET } from '../../hooks/logbox/preset'
import { PageIdSearchParams } from '../../providers/page-id-context'
import isEmpty from 'lodash-es/isEmpty'
import { TmapApp } from '@tmap-web-lib/tmap-app-interface'
import { useSelector } from 'react-redux'
import { selectSettingsEuk, selectSettingsIsCarLifeTermsAgreed } from '../../store/settings'
import { useGetTermsAgreement, useUpdateTermsAgreement } from '../../react-query'
import { useGetTermsInfo } from '../../pages/Etc/hook'
import { TermsInfoType } from './DialogGeneralTerms'
import { TermsAgreements } from '@tmap-web-lib/remote-api-client/frontman'
import { TermsContents } from '../../pages/Etc'
import { TermsAgreeListType } from '@tmap-web-lib/remote-api-client/frontman'

function DialogSingleTerms(props: DialogWithAnimationProps) {
  const {
    optionalTermsGroupData,
    wrapperClassName,
    title,
    okText,
    cancelText,
    okClassName,
    cancelClassName,
    footerClassName,
    onOk,
    onCancel,
  } = props
  const euk = useSelector(selectSettingsEuk)
  const ref = useRef(null)
  const backgroundRef = useRef(null)
  const [eventLog, exposeLog] = useLogBox(['event', 'expose'], {
    attributesPreset: TERMS_TELE_MARKETING_LOG_PRESET.ATTRIBUTES,
    customFieldsPreset: TERMS_TELE_MARKETING_LOG_PRESET.CUSTOM_FIELDS,
    withEuk: true
  })
  const isCarLifeTermsAgreed = useSelector(selectSettingsIsCarLifeTermsAgreed)

  const [termsData, setTermsData] = useState<TermsAgreements[]>([])
  const [targetTermsCode, setTargetTermsCode] = useState('')
  const [termsList, setTermsList] = useState<TermsAgreeListType[]>([])

  const { data: termsAgreementData } = useGetTermsAgreement({
    termsType: 'UBS01',
    termsAllowCode: targetTermsCode,
    enabled: isCarLifeTermsAgreed === true && !!targetTermsCode,
  })
  const updateTermsAgreement = useUpdateTermsAgreement()

  const termsInfo = useGetTermsInfo({
    termsGroupData: optionalTermsGroupData,
    termsData,
  })

  const [modalIsOpen, setModalIsOpen] = useState(true)
  // 전체 동의 버튼 활성 여부
  const [,setAllPoliciesChecked] = useState(false)
  const [isCheckedList, setIsCheckedList] = useState<boolean[]>([])
  const [isDisabled, setIsDisabled] = useState(true)
  // 연타를 막기 위한 상태값
  const [inactive, setInactive] = useState(false)

  const handleOk = useCallback(() => {
    setInactive(true)
    if (!isDisabled) {
      if (termsList) {
        const updateTermsList: Pick<TermsAgreements, 'allowCode' | 'allowYn' | 'allowTitle'>[]  = termsList.map((terms, index) => {
          return {
            allowCode: `${terms.termsType}${terms.termsCode}`,
            allowTitle: `${terms.termsViewTitle}`,
            allowYn: isCheckedList[index] ? 'Y' : 'N',
          }
        })

        updateTermsAgreement.mutateAsync(updateTermsList.map((terms) => terms), {
          onSuccess: () => {
            eventLog().set('action_id', 'tap.agree').send()
          },
          onError: () => {
            onOk?.()
          },
          onSettled: () => {
            setModalIsOpen(false)
          }
        })
      }

    }
  }, [isDisabled, termsList, eventLog, isCheckedList, updateTermsAgreement, onOk])

  const handleCancel = useCallback(() => {
    setModalIsOpen(false)
    eventLog().set('action_id', 'tap.disagree').send()
    setTimeout(async () => {
      onCancel?.()
    }, 500)
  }, [onCancel, eventLog])


  const sortedTerms = useMemo(() => {
    if (termsInfo?.length) {
      const list: TermsInfoType[] = []
      const filtered = termsInfo.filter((terms) => terms.termsCode === targetTermsCode).map((terms) => {
        return { ...terms, sortNo: 1 }
      })
      if (filtered[0]?.subTermsList) {
        const sorted = filtered[0].subTermsList?.map((sub,index) => {
          return { ...sub, sortNo: index + 2 }
        })

        list.push(...filtered)
        list[0].subTermsList = sorted
        return list
      }
    }
  },[termsInfo, targetTermsCode])

  const displayTerms = useMemo(() => {
    if (sortedTerms && sortedTerms.length) {
      return (
        <>
          <DialogContent
            title={title}
            content={
              <>
                <TermsContents
                  termsInfo={sortedTerms}
                  isCheckedList={isCheckedList}
                  setIsCheckedList={setIsCheckedList}
                  setAllPoliciesChecked={setAllPoliciesChecked}
                  eventLog={eventLog}
                  isSingleTerms
                />
              </>
            }
          />
          <DialogFooter
            className={classNames(footerClassName, 'type_flex_between')}
            okButton={
              <DialogOkButton
                className={classNames(isDisabled ? 'clickable-disabled' : okClassName, inactive ? 'inactive' : undefined)}
                disabled={isDisabled || inactive}
                styleFontWeight={!isDisabled ? 'bold' : 'normal'}
                onClick={handleOk}
              >
                {okText}
              </DialogOkButton>
            }
            cancelButton={
              <DialogCancelButton
                className={cancelClassName}
                onClick={handleCancel}
              >
                {cancelText}
              </DialogCancelButton>
            }
          />
        </>
      )
    }
  }, [isDisabled, inactive, cancelClassName, cancelText, footerClassName, handleCancel, handleOk, okClassName, okText, title, eventLog, isCheckedList, sortedTerms])


  useEffect(() => {
    const searchParams = getSearchParams<PageIdSearchParams>()
    if (!isEmpty(searchParams) && searchParams.extra) {
      const extraObject = JSON.parse(searchParams.extra as string)
      const extraCustomFields = extraObject.logbox

      if (extraCustomFields) {
        Object.keys(extraCustomFields).forEach(key => {
          TERMS_TELE_MARKETING_LOG_PRESET.CUSTOM_FIELDS.push([key, extraCustomFields[key]])
        })
      }
    } else {
      TERMS_TELE_MARKETING_LOG_PRESET.CUSTOM_FIELDS.push(['type', 'tab'], ['origin', 'tab'])
    }
    exposeLog().send()
    TmapApp.recordEvent({ name: 'drivingscore_Agreement', json: { euk: euk } })
  }, [euk, exposeLog])

  useEffect(() => {
    if (optionalTermsGroupData && optionalTermsGroupData.termsAgreeList) {
      setTermsList(optionalTermsGroupData.termsAgreeList)
    }
  }, [optionalTermsGroupData])

  useEffect(() => {
    if (termsList.length) {
      setTargetTermsCode(termsList.filter(item => !item.parentTermsCode)[0].termsCode)
    }
  }, [termsList])

  useEffect(() => {
    if (termsAgreementData && termsAgreementData.termsAgreements) {
      setTermsData(termsAgreementData.termsAgreements)
    }
  }, [termsAgreementData])

  useEffect(() => {
    setIsCheckedList(new Array(4).fill(false))
  }, [])


  useEffect(() => {
    if (isCheckedList[0]) {
      setIsDisabled(false)
    } else {
      setIsDisabled(true)
    }
  }, [isCheckedList])


  if (!sortedTerms || !termsList.length) return null

  return (
    <>
      <CSSTransition
        classNames="fade-out"
        unmountOnExit={true}
        timeout={800}
        in={modalIsOpen}
        nodeRef={backgroundRef}
        appear
      >
        <DialogBackground className={wrapperClassName} ref={backgroundRef}>
          <CSSTransition
            classNames="slide-up"
            unmountOnExit={true}
            timeout={400}
            in={modalIsOpen}
            nodeRef={ref}
            appear
          >
            <div ref={ref} className={classNames('popup_wrap', 'popup_bottom', 'popup_terms_form')}>
              <div className={classNames('popup_cont')}>
                {displayTerms}
              </div>
            </div>
          </CSSTransition>
        </DialogBackground>
      </CSSTransition>
    </>
  )
}

export default DialogSingleTerms
export { DialogSingleTerms }
