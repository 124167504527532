import React, { useCallback, useRef, useState } from 'react'
import {
  DialogBackground,
  DialogCustom,
  DialogFooter,
} from './Elements'
import { DialogWithAnimationProps } from './types'
import { classNames } from '@tmap-web-lib/utils'
import { CSSTransition } from 'react-transition-group'

function DialogWithAnimation(props: DialogWithAnimationProps) {
  const {
    wrapperClassName,
    customClassName,
    footerClassName,
    animationType,
    title,
    content,
    okText,
    cancelText,
    onOk,
    onCancel,
    onContentClick
  } = props
  const ref = useRef(null)
  const backgroundRef = useRef(null)
  const [modalIsOpen, setModalIsOpen] = useState(true)

  const handleOk = useCallback(() => {
    setModalIsOpen(false)
    setTimeout(() => {
      onOk?.()
    }, 500)

  }, [onOk])

  const handleCancel = useCallback(() => {
    setModalIsOpen(false)
    setTimeout(() => {
      onCancel?.()
    }, 500)

  }, [onCancel])

  const handleContentClick = useCallback(() => {
    setModalIsOpen(false)
    setTimeout(() => {
      onContentClick?.()
    }, 500)
  },[onContentClick])

  return (
    <>
      <CSSTransition
        classNames="fade-out"
        unmountOnExit={true}
        timeout={800}
        in={modalIsOpen}
        nodeRef={backgroundRef}
        appear
      >
        <DialogBackground
          className={wrapperClassName}
          ref={backgroundRef}
        >
          <CSSTransition
            classNames={animationType ?? 'slide-up'}
            unmountOnExit={true}
            timeout={400}
            in={modalIsOpen}
            nodeRef={ref}
            appear
          >
            <div ref={ref} className={classNames('popup_wrap', 'popup_ad')}>
              <div className={classNames('popup_cont')}>
                <DialogCustom
                  title={title}
                  content={
                    <button
                      className="popup_cont_image_wrapper"
                      onClick={handleContentClick}
                    >
                      {content}
                    </button>
                  }
                  className={customClassName}
                />
                <DialogFooter
                  className={classNames(footerClassName, 'type_flex_between')}
                  cancelButton={
                    <button
                      type="button"
                      className={"popup_btn_text"}
                      onClick={handleCancel}
                    >
                      {cancelText}
                    </button>
                  }
                  okButton={
                    <button
                      type="button"
                      className={classNames('popup_btn_text', 'em')}
                      onClick={handleOk}
                    >
                      {okText}
                    </button>
                  }
                />
              </div>
            </div>
          </CSSTransition>
        </DialogBackground>
      </CSSTransition>
    </>
  )
}

export default DialogWithAnimation
export { DialogWithAnimation }
