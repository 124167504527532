import { useCallback } from 'react'
import { SubTermsInfoType } from '../../../components/Dialog/DialogGeneralTerms'

interface Props {
  isCheckedList: boolean[];
  setAllPoliciesChecked: React.Dispatch<React.SetStateAction<boolean>>;
  setIsCheckedList: React.Dispatch<React.SetStateAction<boolean[]>>;
}

function useHandleSubTermsChecked(props: Props) {
  const { isCheckedList, setAllPoliciesChecked, setIsCheckedList } = props

  return useCallback((targetTerms: SubTermsInfoType, parentTermsIndex: number, subTermsList: SubTermsInfoType[]) => {
    const updatedCheckedState = [...isCheckedList]
    const requiredSubTermsList = subTermsList.filter((terms) => terms.required)

    if (targetTerms !== null) {
      if (targetTerms.required) {
        // 상위약관이 체크 안 된 상태이면
        if (!isCheckedList[parentTermsIndex]) {
          // 상위 약관
          updatedCheckedState[parentTermsIndex] = !isCheckedList[parentTermsIndex]
          // 하위 필수 약관 -> 상위 약관 상태 따라감
          if (requiredSubTermsList.length > 1) {
            requiredSubTermsList.forEach((sub) => {
              updatedCheckedState[sub.sortNo - 1] = !isCheckedList[parentTermsIndex]
            })
          } else {
            updatedCheckedState[targetTerms.sortNo - 1] = !isCheckedList[parentTermsIndex]
          }
        } else {
          // 상위 약관 체크 된 상태 -> 모두 해제
          // 상위 약관
          updatedCheckedState[parentTermsIndex] = !isCheckedList[parentTermsIndex]
          subTermsList.forEach((sub) => {
            // 상위 약관 상태를 따라감
            updatedCheckedState[sub.sortNo - 1] = !isCheckedList[parentTermsIndex]
          })
        }
      } else {
        // 상위 약관이 true 이면 하위 필수 약관도 true
        if (updatedCheckedState[parentTermsIndex]) {
          // 체크한 하위 선택 약관
          updatedCheckedState[targetTerms.sortNo - 1] = !isCheckedList[targetTerms.sortNo - 1]
        } else {
          // 상위 약관
          updatedCheckedState[parentTermsIndex] = !isCheckedList[parentTermsIndex]
          // 하위 필수 약관
          requiredSubTermsList.forEach((sub) => {
            updatedCheckedState[sub.sortNo - 1] = !isCheckedList[parentTermsIndex]
          })
          // updatedCheckedState[parentTermsIndex + 1] = !isCheckedList[parentTermsIndex + 1]
          // 체크한 하위 선택 약관
          updatedCheckedState[targetTerms.sortNo - 1] = !isCheckedList[targetTerms.sortNo - 1]
        }
      }
    }

    const isAllChecked = updatedCheckedState.every((checkedState) => checkedState)
    setAllPoliciesChecked(isAllChecked)
    setIsCheckedList(updatedCheckedState)
  }, [isCheckedList, setAllPoliciesChecked, setIsCheckedList])
}

export { useHandleSubTermsChecked }
