// DEVTOOLS
export const STORAGE_KEY_LIFE_DEV_TOOL_ACCESS_KEY = 'LIFE-DEV-TOOLS-ACCESS-KEY'
export const STORAGE_KEY_LIFE_DEV_TOOL_MOCK_ENABLED = 'LIFE-DEV-TOOLS-MOCK-ENABLED'


// SERVICE
export const STORAGE_KEY_LIFE_STORE_ACCESS_KEY = 'LIFE-STORE-ACCESS-KEY'
export const STORAGE_KEY_LIFE_STORE_MAIN_POPUP_SUPPRESSED = 'LIFE-STORE-MAIN-POPUP-SUPPRESSED'
export const STORAGE_KEY_LIFE_SCHEME_URL = 'LIFE-STORE-SCHEME-URL'
export const STORAGE_KEY_LIFE_UPDATE_OUTER_CAR_INFO = 'LIFE-STORE-UPDATE_OUTER-CAR-INFO'
export const STORAGE_KEY_LIFE_VEHICLE_MAINTENANCE_UPDATE_ITEMS = 'LIFE-STORE-VEHICLE_MAINTENANCE_UPDATE_ITEMS'
export const STORAGE_KEY_LIFE_EMERGENCY_CALL_CHECKED = 'LIFE-STORE-EMERGENCY-CALL-CHECKED'
export const STORAGE_KEY_LIFE_MEDIA_FLOATING_BUTTON = 'LIFE-STORE-MEDIA-FLOATING-BUTTON'
export const STORAGE_KEY_LIFE_OPTIONAL_MARKETING_TERMS_STATUS = 'LIFE-STORE-OPTIONAL-MARKETING-TERMS-STATUS'
