import {
  getTotalSafeDriving,
  TotalSafeDrivingInfoResData,
  TotalSafeDrivingRequestParams,
} from '@tmap-web-lib/remote-api-client/frontman'
import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { KEYS } from '../keys'
import mixpanel from 'mixpanel-browser'
import { datadogRum } from '@datadog/browser-rum'
import { useSelector } from 'react-redux'
import { selectSettingsMockEnabled } from '../../store/settings'
import { mockHost } from '../../api'

function useGetTotalSafeDriving(props: TotalSafeDrivingRequestParams) {
  const { latestTripIdYn, estimatedScoreYn } = props
  const isMockEnabled = useSelector(selectSettingsMockEnabled)

  return useQuery<TotalSafeDrivingInfoResData, AxiosError>([KEYS.TOTAL_SAFE_DRIVING],
    async () => {
      if (JSON.parse(isMockEnabled as string)) {
        const { data } = await mockHost.get<TotalSafeDrivingInfoResData>('/ubds/trip-report/v1/channel/ubds-rmi/RestApi/SafeDrivingService/findTotalSafeDrivingInfo')
        return data
      } else {
        const { data } = await getTotalSafeDriving({ latestTripIdYn, estimatedScoreYn })
        if (data.status !== 'S') {
          mixpanel.track(data.statusMsg ? `tscore:error:findTotalSafeDrivingInfo:${data.statusMsg}` : 'tscore:error:findTotalSafeDrivingInfo', { data })
          datadogRum.addError(data, {
            error: {
              response: data,
              message: data.statusMsg ? `tscore:error:findTotalSafeDrivingInfo:${data.statusMsg}` : 'tscore:error:findTotalSafeDrivingInfo',
            },
          })
        }
        return data
      }
    },
  )
}

export { useGetTotalSafeDriving }
