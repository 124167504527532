import React, { Fragment, useCallback } from 'react'
import { TmapLogBuilder } from '@tmap-web-lib-close/logbox-client'
import { BenefitListDataType } from '../Benefit/hooks'
import { SectionCard } from '../../components'
import { BenefitList } from '../Benefit/BenefitList'
import { RedDotLog, useOpenServiceUrl } from '../../hooks'
import { getBenefitDisplayType } from '../../utils'
import { ReportsResponseData } from '@tmap-web-lib/remote-api-client/frontman'

interface Props {
  data: BenefitListDataType[] | undefined
  drivingScoreData?: ReportsResponseData
  redDotLog: RedDotLog
  eventLog: () => TmapLogBuilder
}

function MainBenefitList({ data, drivingScoreData, redDotLog, eventLog }: Props) {
  const openServiceUrl = useOpenServiceUrl()

  const handleClickMore = useCallback((item: BenefitListDataType) => {
    const extra = { seq: item.categoryId }
    eventLog()
      .set('action_id', 'tap.view_all_benefits')
      .addCustomField('category_id', item.categoryId)
      .addCustomField('category_title', item.categoryName)
      .addCustomField('reddot', redDotLog || 'n')
      .send()
    openServiceUrl(`tmap://life?pageid=benefit&extra=${encodeURIComponent(JSON.stringify(extra))}`)
  }, [redDotLog, openServiceUrl, eventLog])

  if (!data || data.length === 0) return null

  return (
    <>
      {data.map((item, index) => {
        const { categoryId, categoryName, categoryDescription, option} = item
        const { isNew, display } = option

        return (
          <Fragment key={categoryId}>
            {display.exposureAreas[0].type !== 'CARD' ? (
              <SectionCard
                title={categoryName}
                description={categoryDescription}
                isNew={isNew}
                isMore={display.exposureAreas[0].type !== 'CARD'}
                handleClickMore={() => handleClickMore(item)}
              >
                <BenefitList
                  data={item}
                  drivingScoreData={drivingScoreData}
                  displayType={getBenefitDisplayType(display.exposureAreas, 'MAIN')}
                  className="exception"
                  redDotLog={redDotLog}
                  eventLog={eventLog}
                />
              </SectionCard>
            ) : (
              <BenefitList
                data={item}
                drivingScoreData={drivingScoreData}
                displayType={getBenefitDisplayType(display.exposureAreas, 'MAIN')}
                className="exception"
                redDotLog={redDotLog}
                eventLog={eventLog}
              />
            )}
          </Fragment>
        )
      })}
    </>
  )
}

export { MainBenefitList }
